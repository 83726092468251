import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Odwiedź nasz gabinet kosmetyczny w Olsztynie | Gabinet Elżbieta</title>
    <meta name="description" content="Zapraszamy do naszego gabinetu kosmetycznego Elżbieta. Olsztyn ul. Mazurska 6B, tel: 516 908 117" />
    <link rel="canonical" href="https://gabinetelzbieta.pl/kontakt/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */

  p {
    line-height: 150%;
  }

  .subtitle {
    margin: 0;
    text-transform: uppercase;
  }

  .btn {
    svg {
      width: 30px;
      vertical-align: middle;
      margin: 0 6px 0 -10px;
    }
  }
`;

const Address = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 40px;

  > div {
    width: 100%;

    &:nth-child(1) {
      padding-bottom: 50px;
    }
  }

  ${({ theme }) => theme.mqLaptop} {
    > div {
      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 60%;
      }
    }
  }

  address {
    font-size: 1.8rem;
    margin: 0 0 30px;

    a {
      color: inherit;
    }
  }

  figure {
    margin: 0;
    text-align: center;
  }

  figcaption {
    line-height: 140%;
    font-style: italic;
    margin: 10px 0 0;
    text-align: center;
  }
  
  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 50px;
    max-width: 420px;

    dt,
    dd {
      margin: 0;
      width: 50%;
    }

    dd {
      font-weight: 600;
    }
  }
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Contact = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_3)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">

        <Address>
          <div>
            <p className="subtitle">Zapraszamy</p>
            <h1>Kontakt</h1>
            <h2>Umów wizytę</h2>
            <address
              itemScope="itemscope"
              itemType="http://www.schema.org/LocalBusiness"
            >
              <div>
                tel:
                {' '}
                <a href="tel:+48 516 908 117" itemProp="telephone">+48 516 908 117</a>
              </div>
              <div itemProp="streetAddress">ul. Mazurska 6B</div>
              <div>
                <span className="code" itemProp="postalCode">10-520</span>
                {' '}
                <span className="locality" itemProp="addressLocality">Olsztyn</span>
              </div>
            </address>

            <p>
              <strong>UWAGA</strong>
              : Godziny otwarcia gabinetu są nieregularne.
            </p>

            <p style={{
              marginBottom: '50px',
            }}
            >
              <strong>Rezerwacja terminów odbywa się tylko telefonicznie.</strong>
              <br />
              Przepraszamy, jeśli nie odbierzemy telefonu od razu. Oddzwonimy tak szybko, jak to możliwe.
            </p>

            <p>
              <Link to="tel:+48516908117" title="Zadzwoń do Nas" className="btn btn--send btn--auto">
                <svg
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 27.442 27.442"
                  style={{ enableBackground: 'new 0 0 27.442 27.442' }}
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469zM19.995,21.1H7.448V3.373h12.547V21.1z" />
                  </g>
                </svg>

                Zadzwoń i umów się na wizytę
              </Link>
            </p>

          </div>
          <div>
            <figure>
              <GatsbyImage
                image={getImage(data.contact)}
                alt="Gabinet Elżbieta - wejście od ulicy Mazurskiej"
                loading="eager"
              />
              <figcaption>Znajdujemy się na ul.&nbsp;Mazurskiej 6B&nbsp;w&nbsp;Olszynie&nbsp;;)</figcaption>
            </figure>
          </div>
        </Address>

        <iframe
          title="Gabinet Elżbieta"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12399.632586104633!2d20.47509235602885!3d53.78152699159203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e27ed6908dd021%3A0xd899368be7b52ff3!2sGabinet%20Kosmetyczny%20El%C5%BCbieta!5e0!3m2!1spl!2spl!4v1662668265249!5m2!1spl!2spl"
          width="100%"
          height="450"
          style={{
            border: '0',
          }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />

      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_3: file(relativePath: {regex: "/hero\/3.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    contact: file(relativePath: {regex: "/contact\/wejscie-do-gabinetu/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 555
        )
      }
    }
  }
`;

export default Contact;
